import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import SetInterval from '@/plugins/SetInterval'
import router from './router';
import './NaviStrData';

Vue.config.productionTip = false;
Vue.use(SetInterval)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
