<template>
    <div>
      <v-app-bar color="primary" dark app clipped-left>
        <v-toolbar-title>ノード更新</v-toolbar-title>
      </v-app-bar>
      <v-main class="pr-0 pl-2">
      <v-container>
        <v-alert v-model="alerts" dense type="success" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerte" dense type="error" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alertw" dense type="warning" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerti" dense type="info" dismissible>{{ message }}</v-alert>
        
        <v-row class="ma-auto">
          <h2>基本設定</h2>
        </v-row>
        <v-row class="ma-auto">
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-subheader>名前
              <v-icon small class="mr-2" @click="openNavi(1)">
                mdi-information-outline
              </v-icon>
            </v-subheader>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
            <v-text-field
                v-model="editItem.name"
                :rules="rules"
                counter="100"
                label="ノードの名前"
                placeholder="1文字以上100文字以内で入力してください。"
                :error="nameError"
                maxlength="100"
                outlined
                >
            </v-text-field>
          </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-subheader>サーバー構成
              <v-icon small class="mr-2" @click="openNavi(2)">
                mdi-information-outline
              </v-icon>
            </v-subheader>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
            <v-select
                v-model="select"
                :items="selectItem"
                outlined
                @change="selectItemIns"
                :disabled="exp1"
                >
            </v-select>
          </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <h2>詳細設定</h2>
        </v-row>
        <v-row class="ma-auto">
          <h4>
            <v-checkbox
            v-model="checkbox"
            @change="checkboxChanged"
            >
              <template v-slot:label>サーバー構成の詳細を自身で設定したい場合はチェックボックスをオンにして「詳細設定項目」の折り畳みを開いて設定してください。</template>
            </v-checkbox>
          </h4>
        </v-row>
        <br/>
        <v-row class="ma-auto">
        <v-expansion-panels :value="expp" :disabled="exp2">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template>
                <v-row no-gutters>
                  <v-col cols="4">
                  詳細設定項目
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              
              <v-card app :disabled="exp3">
              <v-row class="ma-auto">
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-subheader class="justify-center">スペック
                  <v-icon small class="mr-2" @click="openNavi(3)">
                    mdi-information-outline
                  </v-icon>
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-select
                    v-model="editItem.config"
                    :items="selectItemConfig"
                    >
                </v-select>
              </v-col>
              </v-row>
              </v-card>
              
              <v-card app :disabled="exp4">
              <v-row class="ma-auto">
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-subheader class="justify-center">最小台数
                  <v-icon small class="mr-2" @click="openNavi(4)">
                    mdi-information-outline
                  </v-icon>
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <v-select
                    v-model="editItem.min"
                    :items="selectItemMin"
                    :error="minmaxError"
                    :error-messages="minmaxErrorMessages"
                    >
                </v-select>
              </v-col>
              </v-row>
              </v-card>
              
              <v-card app :disabled="exp5">
              <v-row class="ma-auto">
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-subheader class="justify-center">最大台数
                  <v-icon small class="mr-2" @click="openNavi(5)">
                    mdi-information-outline
                  </v-icon>
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <v-select
                    v-model="editItem.max"
                    :items="selectItemMax"
                    :error="minmaxError"
                    :error-messages="minmaxErrorMessages"
                    >
                </v-select>
              </v-col>
              </v-row>
              </v-card>
              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-row>
        
        <br/>
        
        <v-row class="ma-auto">
          <v-btn class="ml-auto" color="green" dark @click="goUpdateNode">
            更新
          </v-btn>
          <v-btn class="ml-1" color="grey" dark @click="cancel">
            キャンセル
          </v-btn>
        </v-row>
      </v-container>
      </v-main>
      
      <v-dialog v-model="confirmdialog" max-width="500px">
        <v-card>
          <v-card-title class="subtitle-1">更新してよろしいですか？</v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="ml-auto" color="grey" dark @click="closeConfirmDialog">キャンセル</v-btn>
            <v-btn class="ml-1" color="blue" dark @click="okConfirm">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-navigation-drawer
          app
          v-model="navi"
          right
          mobile-breakpoint="1000"
          bottom
          hide-overlay
          >
        <v-row class="ma-auto">
          <v-col>
            <h3>設定値について</h3>
          </v-col>
          <v-col cols="2" class="btn-width">
            <v-btn icon small text @click="closeNavi">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <v-row class="ma-2">
            <p v-html="navistr"></p>
          </v-row>
      </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                route: "",
                rules: [v => v.length > 0 || "1文字以上入力してください。"],
                nameError: false,
                editItem: { id: "", name: "", config: "", min: "", max: "", status: "", userid: "", aws_account_id: "", account_name: "", useadv: false, register_time: "", update_time: "", version: "", },
                testItem: { config: "0.5 vCPU  1 GB", min: "1", max: "1" },
                lowItem: { config: "1 vCPU  2 GB", min: "1", max: "1" },
                middleItem: { config: "2 vCPU  4 GB", min: "2", max: "4" },
                highItem: { config: "4 vCPU  8 GB", min: "3", max: "6" },
                select: "",
                selectItem: ["テスト用構成", "小規模用構成", "中規模用構成", "大規模用構成"],
                selectItemConfig: ["0.5 vCPU  1 GB", "1 vCPU  2 GB", "2 vCPU  4 GB", "4 vCPU  8 GB"],
                selectItemMin: ["1", "2", "3", "4", "5", "6"],
                selectItemMax: ["1", "2", "3", "4", "5", "6"],
                minmaxError: false,
                minmaxErrorMessages: [],
                message: "",
                navistr: "",
                alerts: false,
                alerte: false,
                alertw: false,
                alerti: false,
                navi: false,
                confirmdialog: false,
                checkbox: false,
                exp1: false,
                exp2: true,
                exp3: false,
                exp4: false,
                exp5: false,
                expp: undefined,
            };
        },
        watch: {
            confirmdialog (val) {
                val || this.closeConfirmDialog();
                //モーダルになっているダイアログ外の部分をクリックするとダイアログが閉じてしまうという仕様のため必要
            },
        },
        created() { this.initialize(); },
        methods: {
            initialize() {
                //バックエンドからidのnode情報を取得する(Read)
                //let uuid = this.$router.params.id.toString();
                let id = window.location.pathname;
                let strr = id.replace("/updatenode/", "");
                let strs = strr.split("/");
                let uuid = strs[0];
                this.route = strs[1];
                
                if (uuid) {
                    let urlstr = "/node_manager/node/" + uuid;
                    //let url = new window.Request(urlstr);
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
      
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
      
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == '""') {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              self.editItem = {
                                  id: jdata.id, name: jdata.name, config: "", min: String(jdata.min_task), max: String(jdata.max_task),
                                  status: jdata.status, userid: jdata.user_info_id, aws_account_id: jdata.aws_account_id,
                                  account_name: jdata.account_name, useadv: jdata.use_advanced_config,
                                  register_time: jdata.register_time, update_time: jdata.update_time, version: jdata.version,
                              };
                              //サーバー構成
                              if (jdata.basic_config == "SMALL") {
                                self.select = "小規模用構成";
                                self.editItem.config = "1 vCPU  2 GB";
                                
                              } else if (jdata.basic_config == "MIDDLE") {
                                self.select = "中規模用構成";
                                self.editItem.config = "2 vCPU  4 GB";
                              } else if (jdata.basic_config == "LARGE") {
                                self.select = "大規模用構成";
                                self.editItem.config = "4 vCPU  8 GB";
                              } else {
                                self.select = "テスト用構成";
                                self.editItem.config = "0.5 vCPU  1 GB";
                              }
                              
                              //use_advanced_configと詳細設定チェックボックス
                              self.checkbox = self.editItem.useadv;
                              self.checkboxChanged();
                              
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            setMessage(modestr, mesgstr) {
                //メッセージ初期化
                this.message = "";
                this.alerts = false;
                this.alerte = false;
                this.alertw = false;
                this.alerti = false;
                //メッセージを場合分けして該当の欄に表示する
                if (modestr == "S") { this.message = mesgstr; this.alerts = true; }
                if (modestr == "E") { this.message = mesgstr; this.alerte = true; }
                if (modestr == "W") { this.message = mesgstr; this.alertw = true; }
                if (modestr == "I") { this.message = mesgstr; this.alerti = true; }
            },
            getErrorMessage(response) {
                //レスポンスからエラーメッセージを抽出する
                this.message = "";
                response.json().then(data => { this.message = data; });
            },
            openNavi(num) {
                //初期化
                this.navistr = "";
                //ファイルから該当情報を取得してセットする
                let navid = window.navidata[num];
                for (let key in navid) {
                    this.navistr = this.navistr + '<p>' + key + '：</p><p>' + navid[key] + '</p>';
                }
                this.navi = true;
            },
            closeNavi() {
                this.navi = false;
                //初期化
                this.navistr = "";
            },
            selectItemIns() {
              //editItem<-LMHItem
              if (this.select == "テスト用構成") {
                this.editItem = Object.assign(this.editItem, this.testItem);
              } else if (this.select == "小規模用構成") {
                this.editItem = Object.assign(this.editItem, this.lowItem)
              } else if (this.select == "中規模用構成") {
                this.editItem = Object.assign(this.editItem, this.middleItem);
              } else if (this.select == "大規模用構成") {
                this.editItem = Object.assign(this.editItem, this.highItem);
              }
            },
            checkboxChanged() {
              if (this.checkbox) {
                this.exp1 = true;
                this.exp2 = false;
                this.exp3 = false;
                this.exp4 = false;
                this.exp5 = false;
                this.expp = 0;
              } else {
                this.exp1 = false;
                this.exp2 = true;
                this.exp3 = true;
                this.exp4 = true;
                this.exp5 = true;
                this.expp = undefined;
              }
              
            },
            cancel() {
                if (this.route == 1) {
                    //ノード管理画面
                    this.$router.push("/");
                }
                if (this.route == 2) {
                    //ノード詳細画面
                    let movestr = "/detailnode/" + this.editItem.id;
                    this.$router.push(movestr);
                }
            },
            goUpdateNode() {
                //確認ダイアログ表示
                this.confirmdialog = true;
            },
            okConfirm() {
                console.log("okConfirm");
              
                //バックエンドのnodeを更新する(Update)
                this.confirmdialog = false;
                
                //チェックフラグ
                let chkflg = false;
                this.nameError = false;
                this.minmaxError = false;
                this.minmaxErrorMessages = [];
                
                //入力値チェック(文字数)
                let nodename = this.editItem.name;
                if (!nodename) {
                  //"「名前」は1文字以上入力してください。";
                  this.nameError = true; 
                } else if (nodename.length > 100) {
                  //"「名前」は100文字以内で入力してください。";
                  this.nameError = true; 
                } else {
                  chkflg = true;
                }
                
                let minv = null;
                let maxv = null;
                
                //advanced_config
                let advconf = this.editItem.useadv;
                if (this.checkbox) {
                  console.log("a詳細設定ON");
                  advconf = true;
                } else {
                  console.log("a詳細設定OFF");
                  advconf = false;
                }
                
                let specv = "";
                if (advconf) {
                  console.log("b詳細設定ON");
                  // 詳細設定ON
                  // 台数
                  minv = this.editItem.min;
                  maxv = this.editItem.max;

                  //入力値チェック 最小台数＞最大台数
                  if (minv > maxv) {
                    this.minmaxErrorMessages = ["「最大台数」は「最小台数」以上を選択してください。"];
                    this.minmaxError = true;
                    chkflg = false;
                  }
                  
                  
                  //スペック, 台数
                  if (this.editItem.config == "1 vCPU  2 GB") {
                    specv = "SMALL";
                  } else if (this.editItem.config == "2 vCPU  4 GB") {
                    specv = "MIDDLE";
                  } else if (this.editItem.config == "4 vCPU  8 GB") {
                    specv = "LARGE";
                  } else {
                    specv = "TEST";
                  }
                } else {
                  console.log("b詳細設定OFF");
                  // 詳細設定OFF
                  //スペック, 台数
                  if (this.editItem.config == "1 vCPU  2 GB") {
                    specv = "SMALL";
                    minv = 1;
                    maxv = 1;
                  } else if (this.editItem.config == "2 vCPU  4 GB") {
                    specv = "MIDDLE";
                    minv = 2;
                    maxv = 4;
                  } else if (this.editItem.config == "4 vCPU  8 GB") {
                    specv = "LARGE";
                    minv = 3;
                    maxv = 6;
                  } else {
                    specv = "TEST";
                    minv = 1;
                    maxv = 1;
                  }
                }
                
                //advanced_config
                //let advconf = this.editItem.useadv;
                //if (this.checkbox) {
                //  advconf = true;
                //} else {
                //  advconf = false;
                //}
                
                if (!chkflg) {
                  this.setMessage("E", "入力内容にエラーがあります。エラー箇所を確認してください。");
                } else {
                  
                  //チェッククリア
                  let urlstr = "/node_manager/node";
                  //let url = new window.Request(urlstr);
                  let header = new window.Headers();
                  header.append('Access-Control-Allow-Origin', '*');
                  header.append('Content-Type', 'application/json');
                  
                  let self = this;
                  let nodeid = self.editItem.id;
                  
                  let create_message = {status: "S", message: "ノード更新処理を開始しました。"};
  
                  window.fetch(urlstr, { method: 'PUT', headers: header, mode: 'cors', body: JSON.stringify({ "id": nodeid, "name": nodename, "user_info_id": this.editItem.userid, "aws_account_id": this.editItem.aws_account_id, "account_name": this.editItem.account_name, "basic_config": specv, "use_advanced_config": advconf, "min_task": minv,  "max_task": maxv }) })
                    .then((response) => {
                      if (response.status != 200) { self.getErrorMessage(response); self.setMessage("E", "status:" + response.status); } })
                      
                    .then(json => {
                      create_message = {status: "E", message: json.error_code + ": " + json.message};
                      self.$router.push({name: 'managementnode', query: create_message});
                    })
                    .catch((error) => {
                      if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                      else { self.setMessage("E", error); } });
                  
                    
                    (async () => {
                      const sleep = (second) => new Promise(resolve => setTimeout(resolve, second * 1000))
                      
                      // エラーレスポンスを待たずに遷移してしまうため1s sleep
                      await sleep(1)
                      //処理の成否問わず、処理開始インフォメーション画面に移動する
                      //self.$router.push("/processstartinformation/2");
                      self.$router.push({name: 'managementnode', query: create_message});
                    })()
                  
                  }
              },
              closeConfirmDialog() {
                  //キャンセル
                  this.confirmdialog = false;
              },
          },
    };
</script>