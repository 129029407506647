<template>
    <div>
      <v-app-bar color="primary" dark app clipped-left>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-app-bar>
      <v-main>
      <v-container>
        <v-alert v-model="alerti" dense type="info" dismissible>{{ message }}</v-alert>
        <br/>
        <br/>
        <v-btn class="ma-auto" @click="goManagementNode">
          ノード管理画面に戻る
        </v-btn>
      </v-container>
      </v-main>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                route: "",
                title: "",
                message: "処理を開始しました。ボタンを押してノード管理画面に戻ってください。",
                alerti: true,
            };
        },
        created() { this.initialize(); },
        methods: {
            initialize() {
                let id = window.location.pathname;
                let strr = id.replace("/processstartinformation/", "");
                if (strr == "1") { this.title = "ノード作成"; }
                if (strr == "2") { this.title = "ノード更新"; }
            },
            goManagementNode(){
                this.$router.push("/");
            },
        },
    };
</script>