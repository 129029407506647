import Vue from 'vue';
import Router from 'vue-router';
import ManagementNode from './components/ManagementNode.vue';
import CreateNode from './components/CreateNode.vue';
import UpdateNode from './components/UpdateNode.vue';
import DetailNode from './components/DetailNode.vue';
import SecurityGroupSetting from './components/SecurityGroupSetting.vue';
import ProcessStartInformation from './components/ProcessStartInformation.vue';


Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'managementnode',
            component: ManagementNode,
            //component: () => import(ManagementNode)
             props: true
        },
        {
            path: '/createnode',
            name: 'createnode',
            component: CreateNode
        },
        {
            path: '/updatenode/:id/:route',
            name: 'updatenode',
            component: UpdateNode
        },
        {
            path: '/detailnode/:id',
            name: 'DetailNode',
            component: DetailNode
        },
        {
            path: '/securitygroupsetting/:id/:mode',
            name: 'SecurityGroupSetting',
            component: SecurityGroupSetting
        },
        {
            path: '/processstartinformation/:route',
            name: 'ProcessStartInformation',
            component: ProcessStartInformation
        }
    ]
});
