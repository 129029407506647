<template>
  <div>
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              メニュー
            </v-list-item-title>
            <v-divider></v-divider>
            <v-list nav>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>ノード管理</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-content>
        </v-list-item>
      </v-container>
    </v-navigation-drawer>
    <v-app-bar color="primary" dark app clipped-left>
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>ノード管理</v-toolbar-title>
    </v-app-bar>
    <v-main class="pr-2 pl-0">
      <v-container>
        <v-alert v-model="alerts" dense type="success" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerte" dense type="error" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alertw" dense type="warning" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerti" dense type="info" dismissible>{{ message }}</v-alert>
        
        <v-row class="ma-auto">
          <v-btn class="ml-auto" color="white" @click="getDataItems">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn class="ml-1" color="green" dark @click="createNode">
            ＋作成
          </v-btn>
        </v-row>
        
        <v-row>
          <v-checkbox
            v-model="checkbox"
            @change="checkboxChanged"
            label="ステータスが「DELETED」のノードを表示しない"
            class="ma-auto"
            >
          </v-checkbox>
        </v-row>
        
        <v-data-table
            :headers="dataHeader"
            :items="dataItems"
            :items-per-page="10"
            class="elevation-1"
            >
          <template v-slot:[`header.actions`]="{ header }">
            {{ header.text }}
            <v-icon small class="ml-1" @click="openNavi(9)">
              mdi-information-outline
            </v-icon>
          </template>
          <template v-slot:[`header.name`]="{ header }">
            {{ header.text }}
            <v-icon small class="ml-1" @click="openNavi(10)">
              mdi-information-outline
            </v-icon>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-btn text color="primary" @click="detailNode(item)" :disabled="item.isdisable1" class="text-none">{{ item.name }}</v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editNode(item)"
              :disabled="item.isdisable1"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="startNode(item)"
              :disabled="item.isdisable1"
            >
              mdi-power-standby
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="deleteNode(item)"
              :disabled="item.isdisable2"
            >
              mdi-delete
            </v-icon>
          </template>
          <!--
          <template v-slot:no-data>
            <v-btn color="primary" dark @click="getDataItems">
            データ取得
            </v-btn>
          </template>
            -->
        </v-data-table>
      </v-container>
    </v-main>
    
    <v-dialog v-model="confirmdialog" max-width="500px">
      <v-card>
        <v-card-title class="subtitle-1">削除すると元に戻すことができません。削除してよいですか？</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-auto" color="grey" dark @click="closeConfirmDialog">キャンセル</v-btn>
          <v-btn class="ml-1" color="blue" dark @click="deleteConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-navigation-drawer
          app
          v-model="navi"
          right
          mobile-breakpoint="1000"
          bottom
          hide-overlay
          >
      <v-row class="ma-auto">
      <v-col>
        <h3>設定値について</h3>
      </v-col>
      <v-col cols="2" class="btn-width">
        <v-btn icon small text @click="closeNavi">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="ma-2">
        <p v-html="navistr"></p>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      dataHeader: [
        { text: "id", align: "start", sortable: false, value: "id", },
        { text: "名前", value: "name" },
        { text: "アカウント", value: "account_name" },
        { text: "ステータス", value: "status" },
        { text: "操作", sortable: false, value: "actions" },
      ],
      dataItems: [],
      defaultItem: { id: "" },
      editItem: { id: "" },
      deleteItem: { id: "" },
      message: "",
      navistr: "",
      alerts: false,
      alerte: false,
      alertw: false,
      alerti: false,
      navi: false,
      confirmdialog: false,
      checkbox: true,
    };
  },
  watch: {
    confirmdialog (val) {
      val || this.closeConfirmDialog();
      //モーダルになっているダイアログ外の部分をクリックするとダイアログが閉じてしまうという仕様のため必要
    },
  },
  created() {
    this.getDataItems(); 
    this.$setInterval(() => {
      // 更新処理
      this.getDataItems();
    }, 10000)
    
    if(this.$route.query.message != undefined) {
      let status = this.$route.query.status;
      let message = this.$route.query.message;
      this.setMessage(status, message);
    }

  },
  methods: {

    setMessage(modestr, mesgstr) {
      //メッセージ初期化
      this.message = "";
      this.alerts = false;
      this.alerte = false;
      this.alertw = false;
      this.alerti = false;
      //メッセージを場合分けして該当の欄に表示する
      if (modestr == "S") { this.message = mesgstr; this.alerts = true; }
      if (modestr == "E") { this.message = mesgstr; this.alerte = true; }
      if (modestr == "W") { this.message = mesgstr; this.alertw = true; }
      if (modestr == "I") { this.message = mesgstr; this.alerti = true; }
    },
    getErrorMessage(response) {
      //レスポンスからエラーメッセージを抽出する
      this.message = "";
      response.json().then(data => { this.message = data; });
    },
    openNavi(num) {
      //初期化
      this.navistr = "";
      //ファイルから該当情報を取得してセットする
      let navid = window.navidata[num];
      for (let key in navid) {
        this.navistr = this.navistr + '<p>' + key + '：</p><p>' + navid[key] + '</p>';
      }
      this.navi = true;
    },
    closeNavi() {
      this.navi = false;
      //初期化
      this.navistr = "";
    },
    getDataItems() {
      //バックエンドからnode一覧を取得する(Read)
      let urlstr = "/node_manager/node";
      //let url = new window.Request(urlstr);
      let header = new window.Headers();
      header.append('Access-Control-Allow-Origin', '*');
      
      let self = this;
      let bodytext = "";
      const dec = new TextDecoder();
      
      window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
        .then((response) => response.body.getReader())
        .then((reader) => {
          function readChunk({done, value}){
            if (done) {
              if (bodytext == "[]") {
                //self.setMessage("I", "作成済のノードがありません。");
                self.dataItems = [];
                return;
              }
              let jdata = JSON.parse(bodytext);
              if (typeof(jdata.message) != "undefined") {
                self.setMessage("E", jdata.message);
                return;
              }
              
              let arry = [];
              
              for (let i=0; i<jdata.length; i++) {
                if (self.checkbox && jdata[i].status == "DELETED") {
                  //スキップ
                } else {
                  //ノードのステータスによってボタンの活性非活性を変える
                  let icon1 = true;
                  let icon2 = true;
                  if (jdata[i].status == "RUNNING" || jdata[i].status == "STOPPED") { icon1 = false; icon2 = false; }
                  if (jdata[i].status == "START_FAILED" || jdata[i].status == "DELETE_FAILED") { icon2 = false; }
                  //データ格納
                  let idata = { id: jdata[i].id, name: jdata[i].name, account_name: jdata[i].account_name, status: jdata[i].status , isdisable1: icon1, isdisable2: icon2 };
                  arry.push(idata);
                }
              }
              
              self.dataItems = arry;
              
              return;
            }
            bodytext = bodytext + dec.decode(value);
            reader.read().then(readChunk);
          }
          reader.read().then(readChunk);
        })
        .catch((error) => {
          if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
          else { self.setMessage("E", error); } });
    },
    createNode() {
      //ノード作成画面へ
      this.$router.push("/createnode");
    },
    detailNode(item) {
      //ノード詳細画面へ
      this.editItem = Object.assign({}, item);
      let movestr = "/detailnode/" + this.editItem.id;
      this.$router.push(movestr);
    },
    editNode(item) {
      //バックエンドのnodeを更新する(Update)
      this.editItem = Object.assign({}, item);
      let movestr = "/updatenode/" + this.editItem.id + "/1";
      this.$router.push(movestr);
    },
    startNode(item) {
      //nodeの起動と停止
      this.editItem = Object.assign({}, item);
      this.setMessage("I", "現在、起動・停止ボタンは動作しません。");
      //初期化
      this.editItem = Object.assign({}, this.defaultItem);
    },
    deleteNode(item) {
      //バックエンドのnodeを削除する(Delete)
      this.deleteItem = Object.assign({}, item);
      //削除確認ダイアログ表示
      this.confirmdialog = true;
    },
    deleteConfirm() {
      //削除決行
      this.confirmdialog = false;
      let urlstr = "/node_manager/node/" + this.deleteItem.id;
      //let url = new window.Request(urlstr);
      let header = new window.Headers();
      header.append('Access-Control-Allow-Origin', '*');
      
      let self = this;
      
      window.fetch(urlstr, { method: 'DELETE', headers: header, mode: 'cors' })
        .then((response) => {
          if (response.status != 200) {
            if(response.status == 504) {
              // Timeoutエラーは何もしない。
            } else {
              self.getErrorMessage(response); self.setMessage("E", "status:" + response.status); 
            }
          }
        })
        .catch((error) => { self.setMessage("E", error); });
      
      //初期化
      this.deleteItem = Object.assign({}, this.defaultItem);
      //処理の成否問わず、メッセージを出す
      self.setMessage("S", "削除処理を実行しました。");
    },
    closeConfirmDialog() {
      //削除キャンセル
      //初期化
      this.deleteItem = Object.assign({}, this.defaultItem);
      this.confirmdialog = false;
    },
    checkboxChanged() {
      this.getDataItems();
    },
  },
};
</script>