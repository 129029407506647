//サイドナビゲーションに表示する設定値の説明データ
//"呼び出し番号": { 見出し: 内容, ... } 呼び出し番号の重複は不可
window.navidata = {
    "1": { "項目名": "名前", "説明": "ノードの名前を入力してください。", "入力制限": "1文字以上、100文字以内で入力してください。", "備考": "登録後も更新可能です。" },
    "2": { "項目名": "サーバー構成", "説明": "作成したいサーバーの構成規模を選択してください。", "入力制限": "いずれかを選択してください。", "備考": "登録後は詳細設定の項目毎に更新可能です。" },
    "3": { "項目名": "スペック", "説明": "作成したいノードのスペックを選択してください。", "入力制限": "いずれかを選択してください。", "備考": "登録後も更新可能です。" },
    "4": { "項目名": "最小台数", "説明": "台数を選択してください。", "入力制限": "いずれかを選択してください。", "備考": "登録後も更新可能です。" },
    "5": { "項目名": "最大台数", "説明": "最小台数以上の台数を選択してください。", "入力制限": "いずれかを選択してください。", "備考": "登録後も更新可能です。" },
    "6": { "項目名": "プロトコル", "説明": "プロトコルを選択してください。", "入力制限": "いずれかを選択してください。", "備考": "「すべてのtcp」にしたい場合はプロトコル「tcp」ポート「0-65535」IPアドレス「0.0.0.0/0」としてください。" },
    "7": { "項目名": "ポート", "説明": "ポート番号を半角で入力してください。", "入力制限": "半角数字とハイフンで有効なポート番号を入力してください。", "備考": "「すべてのtcp」にしたい場合はプロトコル「tcp」ポート「0-65535」IPアドレス「0.0.0.0/0」としてください。" },
    "8": { "項目名": "IPアドレス", "説明": "IPアドレスを半角で入力してください。", "入力制限": "CIDR表記で入力してください。", "備考": "「すべてのtcp」にしたい場合はプロトコル「tcp」ポート「0-65535」IPアドレス「0.0.0.0/0」としてください。" },
    "9": { "項目名": "ノード一覧表の各行のボタン", "説明": "左から「更新」「起動・停止」「削除」ボタンです。", "「更新」「起動・停止」ボタンの活性条件": "ノードのステータスが「RUNNING」「STOPPED」のとき。", "「削除」ボタンの活性条件": "ノードのステータスが「RUNNING」「STOPPED」「START_FAILED」「DELETE_FAILED」のとき。" },
    "10": { "項目名": "ノードの名前", "説明": "クリックするとノード詳細画面に移動します。", "活性条件": "ノードのステータスが「RUNNING」「STOPPED」のとき。" },
    "11": { "項目名": "ノードの状態", "説明": "実行中のタスクと保留中のタスクの合計がノードの台数になります。", "備考": "開発中のため文言は適切ではありません。" },
    "12": { "項目名": "AWSアカウント", "説明": "どのAWSアカウントでノードを作成するか選択してください。", "入力制限": "いずれかを選択してください。", "備考": "登録後は変更できません。" },
    "13": { "項目名": "インフォメーション", "説明": "インフォメーションテスト。", "入力制限": "確認中。", "備考": "開発中のため文言は適切ではありません。" },
};
