<template>
    <div>
      <v-app-bar color="primary" dark app clipped-left>
        <v-toolbar-title>ノード作成</v-toolbar-title>
      </v-app-bar>
      <v-main class="pr-0 pl-2">
      <v-container>
        <v-alert v-model="alerts" dense type="success" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerte" dense type="error" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alertw" dense type="warning" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerti" dense type="info" dismissible>{{ message }}</v-alert>
        
        <v-row class="ma-auto">
          <h2>基本設定</h2>
        </v-row>
        
        <v-row class="ma-auto">
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-subheader>AWSアカウント
              <v-icon small class="mr-2" @click="openNavi(12)">
                mdi-information-outline
              </v-icon>
            </v-subheader>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
            <v-select
                v-model="accountItem"
                :items="accountItems"
                item-text="name"
                outlined
                return-object
                >
            </v-select>
          </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-subheader>名前
              <v-icon small class="mr-2" @click="openNavi(1)">
                mdi-information-outline
              </v-icon>
            </v-subheader>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
            <v-text-field
                v-model="createItem.name"
                :rules="rules"
                counter="100"
                label="ノードの名前"
                placeholder="1文字以上100文字以内で入力してください。"
                :error="nameError"
                maxlength="100"
                outlined
                >
            </v-text-field>
          </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-subheader>サーバー構成
              <v-icon small class="mr-2" @click="openNavi(2)">
                mdi-information-outline
              </v-icon>
            </v-subheader>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
            <v-select
                v-model="select"
                :items="selectItem"
                outlined
                @change="selectItemIns"
                :disabled="exp1"
                >
            </v-select>
          </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <h2>詳細設定</h2>
        </v-row>
        <v-row class="ma-auto">
          <h4>
            <v-checkbox
            v-model="checkbox"
            @change="checkboxChanged"
            >
              <template v-slot:label>サーバー構成の詳細を自身で設定したい場合はチェックボックスをオンにして「詳細設定項目」の折り畳みを開いて設定してください。</template>
            </v-checkbox>
          </h4>
        </v-row>
        <br/>
        <v-row class="ma-auto">
        <v-expansion-panels :value="expp" :disabled="exp2">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template>
                <v-row no-gutters>
                  <v-col cols="4">
                  詳細設定項目
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              
              <v-card app :disabled="exp3">
              <v-row class="ma-auto">
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-subheader class="justify-center">スペック
                  <v-icon small class="mr-2" @click="openNavi(3)">
                    mdi-information-outline
                  </v-icon>
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-select
                    v-model="createItem.config"
                    :items="selectItemConfig"
                    >
                </v-select>
              </v-col>
              </v-row>
              </v-card>
              
              <v-card app :disabled="exp4">
              <v-row class="ma-auto">
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-subheader class="justify-center">最小台数
                  <v-icon small class="mr-2" @click="openNavi(4)">
                    mdi-information-outline
                  </v-icon>
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <v-select
                    v-model="createItem.min"
                    :items="selectItemMin"
                    :error="minmaxError"
                    :error-messages="minmaxErrorMessages"
                    >
                </v-select>
              </v-col>
              </v-row>
              </v-card>
              
              <v-card app :disabled="exp5">
              <v-row class="ma-auto">
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-subheader class="justify-center">最大台数
                  <v-icon small class="mr-2" @click="openNavi(5)">
                    mdi-information-outline
                  </v-icon>
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <v-select
                    v-model="createItem.max"
                    :items="selectItemMax"
                    :error="minmaxError"
                    :error-messages="minmaxErrorMessages"
                    >
                </v-select>
              </v-col>
              </v-row>
              </v-card>
              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-row>
        
        <br/>
        
        <v-row class="ma-auto">
          <v-btn class="ml-auto" color="green" dark @click="goCreateNode">
            作成
          </v-btn>
          <v-btn class="ml-1" color="grey" dark @click="cancel">
            キャンセル
          </v-btn>
        </v-row>
        
      </v-container>
      </v-main>
      
      <v-dialog v-model="confirmdialog" max-width="500px">
        <v-card>
          <v-card-title class="subtitle-1">作成してよろしいですか？</v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="ml-auto" color="grey" dark @click="closeConfirmDialog">キャンセル</v-btn>
            <v-btn class="ml-1" color="blue" dark @click="okConfirm">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-navigation-drawer
          app
          v-model="navi"
          right
          mobile-breakpoint="1000"
          bottom
          hide-overlay
          >
        <v-row class="ma-auto">
          <v-col>
            <h3>設定値について</h3>
          </v-col>
          <v-col cols="2" class="btn-width">
            <v-btn icon small text @click="closeNavi">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <v-row class="ma-2">
            <p v-html="navistr"></p>
          </v-row>
      </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                rules: [v => v.length > 0 || "1文字以上入力してください。"],
                nameError: false,
                accountItems: [],
                accountItem: { id: "", name: "" },
                createItem: { name: "", config: "0.5 vCPU  1 GB", min: "1", max: "1" },
                testItem: { config: "0.5 vCPU  1 GB", min: "1", max: "1" },
                lowItem: { config: "1 vCPU  2 GB", min: "1", max: "1" },
                middleItem: { config: "2 vCPU  4 GB", min: "2", max: "4" },
                highItem: { config: "4 vCPU  8 GB", min: "3", max: "6" },
                select: "テスト用構成",
                selectItem: ["テスト用構成", "小規模用構成", "中規模用構成", "大規模用構成"],
                selectItemConfig: ["0.5 vCPU  1 GB", "1 vCPU  2 GB", "2 vCPU  4 GB", "4 vCPU  8 GB"],
                //selectItemCpu: ["0.5", "1", "2", "4"],
                //selectItemMemory: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
                selectItemMin: ["1", "2", "3", "4", "5", "6"],
                selectItemMax: ["1", "2", "3", "4", "5", "6"],
                minmaxError: false,
                minmaxErrorMessages: [],
                message: "",
                navistr: "",
                alerts: false,
                alerte: false,
                alertw: false,
                alerti: false,
                navi: false,
                confirmdialog: false,
                checkbox: false,
                exp1: false,
                exp2: true,
                exp3: false,
                exp4: false,
                exp5: false,
                expp: undefined,
            };
        },
        watch: {
            confirmdialog (val) {
                val || this.closeConfirmDialog();
                //モーダルになっているダイアログ外の部分をクリックするとダイアログが閉じてしまうという仕様のため必要
            },
        },
        created() { this.initialize(); },
        methods: {
            initialize() {
                let urlstr = "/node_manager/awsaccount";
                let header = new window.Headers();
                header.append('Access-Control-Allow-Origin', '*');
                
                let self = this;
                let bodytext = "";
                const dec = new TextDecoder();
                
                window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                  .then((response) => response.body.getReader())
                  .then((reader) => {
                      function readChunk({done, value}){
                        if (done) {
                          if (bodytext == "[]") {
                            self.setMessage("E", "アカウント情報の取得に失敗しました。");
                            return;
                          }
                          let jdata = JSON.parse(bodytext);
                          if (typeof(jdata.message) != "undefined") {
                            self.setMessage("E", jdata.message);
                            return;
                          }
                          //取得した情報を設定する
                          let arry = [];
                          
                          for (let i=0; i<jdata.length; i++) {
                            let idata = { id: jdata[i].id, name: jdata[i].name };
                            arry.push(idata);
                          }
                          
                          self.accountItems = arry;
                          
                          //初期値設定
                          self.accountItem = Object.assign(self.accountItem, self.accountItems[0]);
                          
                          return;
                        }
                        bodytext = bodytext + dec.decode(value);
                        reader.read().then(readChunk);
                      }
                      reader.read().then(readChunk);
                  })
                  .catch((error) => {
                    if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                    else { self.setMessage("E", error); } });
            },
            setMessage(modestr, mesgstr) {
                //メッセージ初期化
                this.message = "";
                this.alerts = false;
                this.alerte = false;
                this.alertw = false;
                this.alerti = false;
                //メッセージを場合分けして該当の欄に表示する
                if (modestr == "S") {this.message = mesgstr; this.alerts = true; }
                if (modestr == "E") { this.message = mesgstr; this.alerte = true; }
                if (modestr == "W") { this.message = mesgstr; this.alertw = true; }
                if (modestr == "I") { this.message = mesgstr; this.alerti = true; }
            },
            getErrorMessage(response) {
                //レスポンスからエラーメッセージを抽出する
                this.message = "";
                response.json().then(data => { this.message = data; });
            },
            openNavi(num) {
              //初期化
              this.navistr = "";
              //ファイルから該当情報を取得してセットする
              let navid = window.navidata[num];
              for (let key in navid) {
                this.navistr = this.navistr + '<p>' + key + '：</p><p>' + navid[key] + '</p>';
              }
              this.navi = true;
            },
            closeNavi() {
              this.navi = false;
              //初期化
              this.navistr = "";
            },
            selectItemIns() {
              //createItem<-LMHItem
              if (this.select == "テスト用構成") {
                this.createItem = Object.assign(this.createItem, this.testItem); 
              } else if (this.select == "小規模用構成") {
                this.createItem = Object.assign(this.createItem, this.lowItem);
              } else if (this.select == "中規模用構成") {
                this.createItem = Object.assign(this.createItem, this.middleItem);
              } else if (this.select == "大規模用構成") {
                this.createItem = Object.assign(this.createItem, this.highItem);
              }
            },
            checkboxChanged() {
              if (this.checkbox) {
                this.exp1 = true;
                this.exp2 = false;
                this.exp3 = false;
                this.exp4 = false;
                this.exp5 = false;
                this.expp = 0;
              } else {
                this.exp1 = false;
                this.exp2 = true;
                this.exp3 = true;
                this.exp4 = true;
                this.exp5 = true;
                this.expp = undefined;
              }
            },
            cancel() {
              this.$router.push("/");
            },
            goCreateNode() {
                //確認ダイアログ表示
                this.confirmdialog = true;
            },
            okConfirm() {
              console.log("okConfirm");

              //バックエンドでnodeを作成する(Create)
              this.confirmdialog = false;
              
              //チェックフラグ
              let chkflg = false;
              this.nameError = false;
              this.minmaxError = false;
              this.minmaxErrorMessages = [];
              
              //入力値チェック(文字数)
              let nodename = this.createItem.name;
              if (!nodename) {
                //"「名前」は1文字以上入力してください。";
                this.nameError = true;
              } else if (nodename.length > 100) {
                //"「名前」は100文字以内で入力してください。";
                this.nameError = true;
              } else { 
                chkflg = true;
              }
              
              //入力値チェック 最小台数＞最大台数
              /*
              let minv = this.createItem.min;
              let maxv = this.createItem.max;
              if (minv > maxv) {
                this.minmaxErrorMessages = ["「最大台数」は「最小台数」以上を選択してください。"];
                this.minmaxError = true;
                chkflg = false;
              }
              //スペック
              let specv = "";
              if (this.createItem.config == "1 vCPU  2 GB") {
                specv = "SMALL";
              } else if (this.createItem.config == "2 vCPU  4 GB") {
                specv = "MIDDLE";
              } else if (this.createItem.config == "4 vCPU  8 GB") {
                specv = "LARGE";
              } else {
                specv = "TEST";
              }
              */
              
              //advanced_config
              let advconf = this.createItem.useadv;
              if (this.checkbox) {
                advconf = true;
              } else {
                advconf = false;
              }
              
              let specv = "";
              let minv = null;
              let maxv = null;
              
              if (advconf) {
                console.log("詳細設定ON");
                // 詳細設定ON
                // 台数
                minv = this.createItem.min;
                maxv = this.createItem.max;
                
                if (minv > maxv) {
                  this.minmaxErrorMessages = ["「最大台数」は「最小台数」以上を選択してください。"];
                  this.minmaxError = true;
                  chkflg = false;
                }
                
                
                //スペック, 台数
                if (this.createItem.config == "1 vCPU  2 GB") {
                  specv = "SMALL";
                } else if (this.createItem.config == "2 vCPU  4 GB") {
                  specv = "MIDDLE";
                } else if (this.createItem.config == "4 vCPU  8 GB") {
                  specv = "LARGE";
                } else {
                  specv = "TEST";
                }
              } else {
                console.log("詳細設定OFF");
                // 詳細設定OFF
                //スペック, 台数
                if (this.createItem.config == "1 vCPU  2 GB") {
                  specv = "SMALL";
                  minv = 1;
                  maxv = 1;
                } else if (this.createItem.config == "2 vCPU  4 GB") {
                  specv = "MIDDLE";
                  minv = 2;
                  maxv = 4;
                } else if (this.createItem.config == "4 vCPU  8 GB") {
                  specv = "LARGE";
                  minv = 3;
                  maxv = 6;
                } else {
                  specv = "TEST";
                  minv = 1;
                  maxv = 1;
                }
              }
              
              
              if (!chkflg) {
                this.setMessage("E", "入力内容にエラーがあります。エラー箇所を確認してください。");
              } else {
                
                //チェッククリア
                let urlstr = "/node_manager/node";
                //let url = new window.Request(urlstr);
                let header = new window.Headers();
                header.append('Access-Control-Allow-Origin', '*');
                header.append('Content-Type', 'application/json');
              
                let self = this;
              
                let create_message = {status: "S", message: "ノード作成処理を開始しました。"};
                window.fetch(urlstr, { method: 'POST', headers: header, mode: 'cors', body: JSON.stringify({ "name": nodename, "user_info_id": "", "aws_account_id": this.accountItem.id, "account_name": this.accountItem.name, "basic_config": specv, "use_advanced_config": this.checkbox, "min_task": minv,  "max_task": maxv }) })
                  .then((response) => {
                    if (response.status != 200) {
                      return response.json();
                    }
                  })
                  .then(json => {
                    create_message = {status: "E", message: json.error_code + ": " + json.message};
                    self.$router.push({name: 'managementnode', query: create_message});
                  })
                  .catch((error) => {
                    if (error == "TypeError: Failed to fetch") {
                      self.setMessage("E", "サーバへのアクセスに失敗しました。");
                      create_message = {status: "E", message: "サーバへのアクセスに失敗しました。"};
                    } else { 
                      self.setMessage("E", error); 
                    } 
                  });
                  
                  //
                  (async () => {
                    const sleep = (second) => new Promise(resolve => setTimeout(resolve, second * 1000))
                    
                    // エラーレスポンスを待たずに遷移してしまうため1s sleep
                    await sleep(1)
                    //処理の成否問わず、処理開始インフォメーション画面に移動する
                    //self.$router.push("/processstartinformation/1");
                    self.$router.push({name: 'managementnode', query: create_message});
                  })()
              
                }
              },
              closeConfirmDialog() {
                  //キャンセル
                  this.confirmdialog = false;
              },
        },
    };
</script>