<template>
    <div>
      <v-app-bar color="primary" dark app clipped-left>
        <v-toolbar-title>セキュリティグループ設定</v-toolbar-title>
      </v-app-bar>
      <v-main class="pr-0 pl-2">
      <v-container>
        <v-alert v-model="alerts" dense type="success" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerte" dense type="error" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alertw" dense type="warning" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerti" dense type="info" dismissible>{{ message }}</v-alert>
        
        <v-row class="ma-auto">
          <h2>{{ modeName }}</h2>
        </v-row>
        
        <v-data-table
            :headers="dataHeader"
            :items="boundItems"
            :items-per-page="10"
            class="elevation-1"
            >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="deleteRule(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" dark @click="initialize">
            データ取得
            </v-btn>
          </template>
        </v-data-table>
        
        <br/>
        
        <v-row class="ma-auto">
          <v-btn class="mr-auto" color="green" dark @click="addRule">
            ＋追加
          </v-btn>
          <v-btn class="ml-auto" color="grey" dark @click="cancel">
            戻る
          </v-btn>
        </v-row>
      </v-container>
      </v-main>
      
      <v-dialog v-model="adddialog" max-width="500px">
      <v-card>
        <v-card-title class="subtitle-1">ルール追加</v-card-title>
        <v-alert v-model="dialogalert" dense type="error" dismissible>{{ dialogmessage }}</v-alert>
        <v-row class="ma-2">
          <v-icon small class="mr-2" @click="openNavi(6)">
            mdi-information-outline
          </v-icon>
          <v-select
                v-model="addItem.protocol"
                :items="selectItem"
                label="プロトコル"
                @change="protocolchange"
                >
          </v-select>
        </v-row>
        <v-row class="ma-2">
          <v-icon small class="mr-2" @click="openNavi(7)">
            mdi-information-outline
          </v-icon>
          <v-text-field
                v-model="addItem.port"
                :rules="rules"
                label="ポート"
                placeholder="例) 範囲の場合440-445 SSHの場合22"
                :error="portError"
                :error-messages="portErrorMessages"
                maxlength="11"
                outlined
                :disabled="portdisabled"
                >
          </v-text-field>
        </v-row>
        <v-row class="ma-2">
          <v-icon small class="mr-2" @click="openNavi(8)">
            mdi-information-outline
          </v-icon>
          <v-text-field
                v-model="addItem.ipv4"
                :rules="rules"
                label="IPアドレス"
                placeholder="例) 10.0.0.0/32"
                :error="ipv4Error"
                :error-messages="ipv4ErrorMessages"
                maxlength="18"
                outlined
                >
          </v-text-field>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-auto" color="grey" dark @click="closeAddDialog">キャンセル</v-btn>
          <v-btn class="ml-1" color="green" dark @click="addConfirm">追加</v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
      
      <v-dialog v-model="confirmdialog" max-width="500px">
      <v-card>
        <v-card-title class="subtitle-1">削除すると元に戻すことができません。削除してよろしいですか？</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-auto" color="grey" dark @click="closeConfirmDialog">キャンセル</v-btn>
          <v-btn class="ml-1" color="blue" dark @click="deleteConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
      
      <v-navigation-drawer
          app
          v-model="navi"
          right
          mobile-breakpoint="1000"
          bottom
          hide-overlay
          >
        <v-row class="ma-auto">
          <v-col>
            <h3>設定値について</h3>
          </v-col>
          <v-col cols="2" class="btn-width">
            <v-btn icon small text @click="closeNavi">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <v-row class="ma-2">
            <p v-html="navistr"></p>
          </v-row>
      </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                uuid: "",
                mode: "",
                modeName: "",
                dataHeader: [
                  { text: "プロトコル", value: "protocol", },
                  { text: "ポート", value: "port" },
                  { text: "IPアドレス", value: "ipv4" },
                  { text: "操作", sortable: false, value: "actions" },
                ],
                rules: [v => v.length > 0 || "1文字以上入力してください。"],
                portError: false,
                portErrorMessages: [],
                ipv4Error: false,
                ipv4ErrorMessages: [],
                selectItem: ["tcp", "udp", "icmp"],
                addItem: { protocol: "tcp", port: "", ipv4: "" },
                deleteItem: { protocol: "", port: "", ipv4: "" },
                boundItema: { protocol: "tcp", port: "", ipv4: "" },
                boundItemd: { protocol: "", port: "", ipv4: "" },
                boundItems: [],
                message: "",
                dialogmessage: "",
                navistr: "",
                alerts: false,
                alerte: false,
                alertw: false,
                alerti: false,
                dialogalert: false,
                navi: false,
                adddialog: false,
                portdisabled: false,
                confirmdialog: false,
            };
        },
        watch: {
            adddialog (val) {
                val || this.closeAddDialog();
                //モーダルになっているダイアログ外の部分をクリックするとダイアログが閉じてしまうという仕様のため必要
            },
            confirmdialog (val) {
                val || this.closeConfirmDialog();
                //モーダルになっているダイアログ外の部分をクリックするとダイアログが閉じてしまうという仕様のため必要
            },
        },
        created() { this.initialize(); },
        methods: {
            initialize() {
                let id = window.location.pathname;
                let strr = id.replace("/securitygroupsetting/", "");
                let strs = strr.split("/");
                this.uuid = strs[0];
                this.mode = strs[1];
                
                if (this.mode == "out") {
                    this.modeName = "アウトバウンドルール一覧";
                } else {
                    this.modeName = "インバウンドルール一覧";
                }
                
                if (this.uuid && this.mode) {
                    let urlstr = "/node_manager/" + this.uuid + "/security";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == "{}") {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              let arry = [];
                              let ipPermissions = [];
                              if (self.mode == "out") {
                                ipPermissions = jdata.SecurityGroups[0].IpPermissionsEgress;
                              } else {
                                ipPermissions = jdata.SecurityGroups[0].IpPermissions;
                              }
                              
                              for (let i=0; i<ipPermissions.length; i++) {
                                let iport = "";
                                if ("FromPort" in ipPermissions[i]) {
                                  iport = ipPermissions[i].FromPort;
                                  if (ipPermissions[i].FromPort != ipPermissions[i].ToPort) {
                                    iport = iport + "-" + ipPermissions[i].ToPort;
                                  }
                                }
                                for (let j=0; j<ipPermissions[i].IpRanges.length; j++) {
                                  let idata = { protocol: ipPermissions[i].IpProtocol, port: iport, ipv4: ipPermissions[i].IpRanges[j].CidrIp };
                                  if (idata["protocol"] == "-1") { idata["protocol"] = "すべて"; }
                                  if (idata["protocol"] == "icmp") { idata["port"] = "すべて"; }
                                  arry.push(idata);
                                }
                              }
                              self.boundItems = arry;
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            setMessage(modestr, mesgstr) {
                //メッセージ初期化
                this.message = "";
                this.alerts = false;
                this.alerte = false;
                this.alertw = false;
                this.alerti = false;
                //メッセージを場合分けして該当の欄に表示する
                if (modestr == "S") { this.message = mesgstr; this.alerts = true; }
                if (modestr == "E") { this.message = mesgstr; this.alerte = true; }
                if (modestr == "W") { this.message = mesgstr; this.alertw = true; }
                if (modestr == "I") { this.message = mesgstr; this.alerti = true; }
            },
            setDialogMessage(mesgstr) {
                //dialogalert
                //dialogmessage
                //メッセージ初期化
                this.dialogmessage = "";
                this.dialogalert = false;
                //メッセージ表示
                this.dialogmessage = mesgstr;
                this.dialogalert = true;
            },
            getErrorMessage(response) {
                //レスポンスからエラーメッセージを抽出する
                this.message = "";
                response.json().then(data => { this.message = data; });
            },
            openNavi(num) {
                //初期化
                this.navistr = "";
                //ファイルから該当情報を取得してセットする
                let navid = window.navidata[num];
                for (let key in navid) {
                  this.navistr = this.navistr + '<p>' + key + '：</p><p>' + navid[key] + '</p>';
                }
                this.navi = true;
            },
            closeNavi() {
                this.navi = false;
                //初期化
                this.navistr = "";
            },
            deleteRule(item) {
                //ルール削除
                this.deleteItem = Object.assign({}, item);
                //削除確認ダイアログ表示
                this.confirmdialog = true;
            },
            deleteConfirm() {
                //削除決行
                this.confirmdialog = false;
                //バックエンド呼び出し
                let ippermission = {};
                if (this.mode == "out") {
                    //アウトバウンドルール
                    ippermission = {"id": this.uuid, "protocol": this.deleteItem.protocol, "port": this.deleteItem.port, "ipv4": this.deleteItem.ipv4, "isegress": true};
                } else {
                    //インバウンドルール
                    ippermission = {"id": this.uuid, "protocol": this.deleteItem.protocol, "port": this.deleteItem.port, "ipv4": this.deleteItem.ipv4, "isegress": false};
                }
                if (ippermission["protocol"] == "すべて") { ippermission["protocol"] = "-1"; }
                if (ippermission["protocol"] == "icmp") { ippermission["port"] = "0"; }
                
                let urlstr = "/node_manager/sg_rule";
                let header = new window.Headers();
                header.append('Access-Control-Allow-Origin', '*');
                header.append('Content-Type', 'application/json');
                
                let self = this;
                
                window.fetch(urlstr, { method: 'DELETE', headers: header, mode: 'cors', body: JSON.stringify(ippermission) })
                    .then((response) => {
                        if (response.status == 200) { self.initialize(); self.setMessage("S", "ルールを削除しました。"); }
                        else { self.getErrorMessage(response); self.setMessage("E", "status:" + response.status); } })
                    .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                
                //初期化
                this.deleteItem = Object.assign({}, this.boundItemd);
                this.confirmdialog = false;
            },
            closeConfirmDialog() {
                //削除キャンセル
                //初期化
                this.deleteItem = Object.assign({}, this.boundItemd);
                this.confirmdialog = false;
            },
            addRule() {
                //ルール追加
                this.addItem = Object.assign({}, this.boundItema);
                //ダイアログ表示
                this.adddialog = true;
            },
            addConfirm() {
                //追加決行
                //入力値バリデーション
                //チェックフラグ
                let chkflg = false;
                this.portError = false;
                this.portErrorMessages = [];
                this.ipv4Error = false;
                this.ipv4ErrorMessages = [];
                //ポート
                let portstr = this.addItem.port;
                if (!portstr) {
                  //this.portErrorMessages = ["1文字以上入力してください。"];
                  this.portError = true;
                } else if (!this.checkPort(portstr)) {
                  this.portErrorMessages = ["半角数字とハイフンで有効なポート番号を入力してください。"];
                  this.portError = true;
                } else {
                  //チェッククリア
                  chkflg = true;
                }
                //IPアドレス
                let ipstr = this.addItem.ipv4;
                if (!ipstr) {
                  //this.ipv4ErrorMessages = ["1文字以上入力してください。"];
                  this.ipv4Error = true;
                  chkflg = false;
                } else if (!this.checkIPv4(ipstr)) {
                  this.ipv4ErrorMessages = ["CIDR表記で入力してください。"];
                  this.ipv4Error = true;
                  chkflg = false;
                } else {
                  //チェッククリア
                }
                
                if (!chkflg) {
                  this.setDialogMessage("入力内容にエラーがあります。エラー箇所を確認してください。");
                } else {
                //チェッククリア
                //バックエンド呼び出し
                let ippermission = {};
                if (this.mode == "out") {
                    //アウトバウンドルール
                    ippermission = {"id": this.uuid, "protocol": this.addItem.protocol, "port": this.addItem.port, "ipv4": this.addItem.ipv4, "isegress": true};
                } else {
                    //インバウンドルール
                    ippermission = {"id": this.uuid, "protocol": this.addItem.protocol, "port": this.addItem.port, "ipv4": this.addItem.ipv4, "isegress": false};
                }
                if (ippermission["protocol"] == "すべて") { ippermission["protocol"] = "-1"; }
                if (ippermission["protocol"] == "icmp") { ippermission["port"] = "0"; }
                
                let urlstr = "/node_manager/sg_rule";
                let header = new window.Headers();
                header.append('Access-Control-Allow-Origin', '*');
                header.append('Content-Type', 'application/json');
                
                let self = this;
                
                window.fetch(urlstr, { method: 'POST', headers: header, mode: 'cors', body: JSON.stringify(ippermission) })
                    .then((response) => {
                        if (response.status == 200) { self.initialize(); self.setMessage("S", "ルールを追加しました。"); }
                        else { self.getErrorMessage(response); self.setMessage("E", "status:" + response.status); } })
                    .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                
                //初期化
                this.addItem = Object.assign({}, this.boundItema);
                this.adddialog = false;
                this.portdisabled = false;
                }
            },
            checkPort(chk) {
                //入力値バリデーション関数 ポート番号
                //portstr.match(/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-5][0-9][0-9][0-9][0-9]|6[0-4][0-9][0-9][0-9]|65[0-4][0-9][0-9]|655[0-2][0-9]|6553[0-5])([-]{1}[0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-5][0-9][0-9][0-9][0-9]|6[0-4][0-9][0-9][0-9]|65[0-4][0-9][0-9]|655[0-2][0-9]|6553[0-5])?/) == null
                if (chk.match(/^\d[-0-9]{0,10}$/) == null) {
                    return false;
                }
                let chksp = chk.split("-");
                if (chksp.length > 2) {
                    return false;
                }
                for (let i=0; i<chksp.length; i++) {
                    if (chksp[i].match(/^(\d|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/) == null) {
                        return false;
                    }
                }
                //大小比較＝AWS-boto3ではFrom-Toの大小関係をみていないがエラーにする
                if (chksp.length == 2) {
                    let int1 = parseInt(chksp[0], 10);
                    let int2 = parseInt(chksp[1], 10);
                    if (int1 > int2) {
                        return false;
                    }
                }
                return true;
            },
            checkIPv4(chk) {
                //入力値バリデーション関数 IPアドレス(v4)
                //ipstr.match(/^([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]{1}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]{1}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]{1}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[/]{1}([0-9]|[1-2][0-9]|3[0-2])$/) == null
                if (chk.match(/^\d[0-9./]{7,16}\d$/) == null) {
                    return false;
                }
                let chksp = chk.split(".");
                if (chksp.length != 4) {
                    return false;
                }
                let chksp3 = chksp[3].split("/");
                if (chksp3.length != 2) {
                    return false;
                }
                for (let i=0; i<3; i++) {
                    if (chksp[i].match(/^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/) == null) {
                        return false;
                    }
                }
                if (chksp3[0].match(/^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/) == null) {
                    return false;
                }
                if (chksp3[1].match(/^(\d|[1-2]\d|3[0-2])$/) == null) {
                    return false;
                }
                return true;
            },
            protocolchange() {
                if (this.addItem.protocol == "icmp") {
                    this.addItem.port = "0-65535";
                    this.portdisabled = true;
                } else {
                    this.addItem.port = "";
                    this.portdisabled = false;
                }
            },
            closeAddDialog() {
                //追加キャンセル
                //初期化
                this.addItem = Object.assign({}, this.boundItema);
                this.portError = false;
                this.portErrorMessages = [];
                this.ipv4Error = false;
                this.ipv4ErrorMessages = [];
                this.adddialog = false;
                this.portdisabled = false;
                this.dialogmessage = "";
                this.dialogalert = false;
            },
            cancel() {
                //画面遷移
                if (this.uuid) {
                    let movestr = "/detailnode/" + this.uuid;
                    this.$router.push(movestr);
                }
            }
        },
    };
</script>