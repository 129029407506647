<template>
    <div>
      <v-app-bar color="primary" dark app clipped-left>
        <v-toolbar-title>ノード詳細</v-toolbar-title>
      </v-app-bar>
      <v-main class="pr-0 pl-2">
      <v-container>
        <v-alert v-model="alerts" dense type="success" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerte" dense type="error" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alertw" dense type="warning" dismissible>{{ message }}</v-alert>
        <v-alert v-model="alerti" dense type="info" dismissible>{{ message }}</v-alert>
        
        <v-row class="ma-auto">
            <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <v-card flat>
                <ul>
                <li>ノードの名前：{{ detailItem.name }}</li>
                <li>ノードid：{{ detailItem.id }}</li>
                <li>アカウント：{{ detailItem.account_name }}</li>
                <li>稼働状況：{{ detailItem.status }}</li>
                <li>サーバ構成：{{ getServerConfiguration() }}</li>
                <li>スペック：{{ getServerSpec() }}</li>
                <li>最小台数：{{ detailItem.min_task }}</li>
                <li>最大台数：{{ detailItem.max_task }}</li>
                <li>登録日付：{{ detailItem.register_time }}</li>
                </ul>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
              <v-card flat>
                <ul>
                <li>gBizConnect Node設定画面のURL：</li>
                <v-icon @click="copygBizConnectURL">mdi-content-copy</v-icon><a @click="gogBizConnectURL">{{ domainItem.gBizConnectURL }}</a>
                <li>Swagger UIのURL：</li>
                <v-icon @click="copySwaggerURL">mdi-content-copy</v-icon><a @click="goSwaggerURL">{{ domainItem.SwaggerURL }}</a>
                <li>Swagger EditorのURL：</li>
                <v-icon @click="copySwaggerEditorURL">mdi-content-copy</v-icon><a @click="goSwaggerEditorURL">{{ domainItem.SwaggerEditorURL }}</a>
                </ul>
              </v-card>
            </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <v-btn class="ml-auto" color="green" dark @click="editNode">
            更新
          </v-btn>
        </v-row>
        
        <br/>
        
        <v-row class="ma-auto">
          <v-tabs
              v-model="tab"
              background-color="primary"
              center-active
              dark
              @change="activetab($event)"
              >
            <v-tab href="#tab-1">ノードの状態</v-tab>
            <v-tab href="#tab-2">セキュリティ</v-tab>
            <v-tab href="#tab-3">証明書関連</v-tab>
            <v-tab href="#tab-4">ドメイン設定</v-tab>
            <v-tabs-slider color="yellow" />
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <br/>
              <v-icon small class="mr-2" @click="openNavi(11)">
                mdi-information-outline
              </v-icon>
              <v-row class="ma-auto">
                {{ nodeCondition }}
              </v-row>
              <v-row class="ma-auto">
                {{ statusMessage }}
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <br/>
              <v-row class="ma-auto">
                インバウンドルール
              </v-row>
              <v-data-table
                  :headers="dataHeader"
                  :items="inboundItem"
                  :items-per-page="5"
                  class="elevation-1"
                  >
              </v-data-table>
              <br/>
              <v-row class="ma-auto"><v-btn class="ml-auto" color="green" dark @click="inboundSetting">更新</v-btn></v-row>
              <v-row class="ma-auto">
                アウトバウンドルール
              </v-row>
              <v-data-table
                  :headers="dataHeader"
                  :items="outboundItem"
                  :items-per-page="5"
                  class="elevation-1"
                  >
              </v-data-table>
              <br/>
              <v-row class="ma-auto"><v-btn class="ml-auto" color="green" dark @click="outboundSetting">更新</v-btn></v-row>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <br/>
              <v-row class="ma-auto">
                失効日：{{ certificateItem.RevokedAt }}
              </v-row>
              <br/>
              <v-row class="ma-auto"><v-btn class="ml-auto" color="green" dark @click="certificateSetting">更新</v-btn></v-row>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <br/>
              <v-row class="ma-auto">
                説明文の領域
              </v-row>
              <br/>
              <v-row class="ma-auto">
                SSL証明書
                <v-file-input
                    id="crtfile"
                    accept=".crt, .cer"
                    label="アップロードするファイルを選択してください。"
                    :error="crtError"
                    :error-messages="crtErrorMessages"
                    >
                </v-file-input>
                <v-btn class="ma-auto" color="white" @click="sendCrtfile">送信</v-btn>
              </v-row>
              <br/>
              <v-row class="ma-auto">
                SSL証明書の秘密鍵
                <v-file-input
                    id="keyfile"
                    accept=".key"
                    label="アップロードするファイルを選択してください。"
                    :error="keyError"
                    :error-messages="keyErrorMessages"
                    >
                </v-file-input>
                <v-btn class="ma-auto" color="white" @click="sendKeyfile">送信</v-btn>
              </v-row>
              <br/>
              <v-row class="ma-auto">
                <v-col>
                ロードバランサーのDNS名
                <v-icon @click="copyLoadbalancerDNSName">mdi-content-copy</v-icon><a>{{ loadbalancerItem.DNSName }}</a>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        
        <br/>
        
        <v-row class="ma-auto">
          <v-btn class="ml-auto" color="grey" dark @click="cancel">
            戻る
          </v-btn>
        </v-row>
      </v-container>
      </v-main>
      
      <v-navigation-drawer
          app
          v-model="navi"
          right
          mobile-breakpoint="1000"
          bottom
          hide-overlay
          >
        <v-row class="ma-auto">
          <v-col>
            <h3>設定値について</h3>
          </v-col>
          <v-col cols="2" class="btn-width">
            <v-btn icon small text @click="closeNavi">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <v-row class="ma-2">
            <p v-html="navistr"></p>
          </v-row>
      </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                uuid: "",
                detailItem: { id: "", name: "", user_info_id: "", aws_account_id: "", account_name: "", status: "", basic_config: "", use_advanced_config: "", min_task: "", max_task: "", register_time: "", update_time: "", version: "", },
                domainItem: { subdomain: "", gBizConnectURL: "", SwaggerURL: "", SwaggerEditorURL: "" },
                nodeCondition: "",
                certificateItem: { RevokedAt: "" },
                loadbalancerItem: { DNSName: "" },
                crtError: false,
                crtErrorMessages: [],
                keyError: false,
                keyErrorMessages: [],
                securityItem: { GroupName: "", GroupId: "", Description: "", VpcId: "" },
                inboundItem: [],
                outboundItem: [],
                tagItem: [],
                dataHeader: [
                  { text: "プロトコル", value: "protocol" },
                  { text: "ポート", value: "port" },
                  { text: "IPアドレス", value: "ipv4" },
                ],
                message: "",
                navistr: "",
                alerts: false,
                alerte: false,
                alertw: false,
                alerti: false,
                navi: false,
                tab: null,
            };
        },
        created() { this.initialize(); },
        methods: {
            initialize() {
                let id = window.location.pathname;
                this.uuid = id.replace("/detailnode/", "");
                
                if (this.uuid) {
                    let urlstr = "/node_manager/node/" + this.uuid;
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == '""') {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              self.detailItem = {
                                  id: jdata.id, name: jdata.name, user_info_id: jdata.user_info_id,
                                  aws_account_id: jdata.aws_account_id, account_name: jdata.account_name, status: jdata.status,
                                  basic_config: jdata.basic_config, use_advanced_config: jdata.use_advanced_config,
                                  min_task: jdata.min_task, max_task: jdata.max_task,
                                  register_time: jdata.register_time, update_time: jdata.update_time, version: jdata.version,
                              };
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
                
                //URL情報取得
                this.getDomain();
                //タブ「tab-1」の呼び出し
                this.activetab("tab-1");
            },
            setMessage(modestr, mesgstr) {
                //メッセージ初期化
                this.message = "";
                this.alerts = false;
                this.alerte = false;
                this.alertw = false;
                this.alerti = false;
                //メッセージを場合分けして該当の欄に表示する
                if (modestr == "S") { this.message = mesgstr; this.alerts = true; }
                if (modestr == "E") { this.message = mesgstr; this.alerte = true; }
                if (modestr == "W") { this.message = mesgstr; this.alertw = true; }
                if (modestr == "I") { this.message = mesgstr; this.alerti = true; }
            },
            getErrorMessage(response) {
                //レスポンスからエラーメッセージを抽出する
                this.message = "";
                response.json().then(data => { this.message = data; });
            },
            openNavi(num) {
                //初期化
                this.navistr = "";
                //ファイルから該当情報を取得してセットする
                let navid = window.navidata[num];
                for (let key in navid) {
                    this.navistr = this.navistr + '<p>' + key + '：</p><p>' + navid[key] + '</p>';
                }
                this.navi = true;
            },
            closeNavi() {
                this.navi = false;
                //初期化
                this.navistr = "";
            },
            activetab(tabhrefstr){
                //タブをクリックしたときの動作
                //tab-1は選択状態であり、変化しないとトリガーしないため、tab-1の情報取得はinitialize()でやる必要がある
                if (tabhrefstr == "tab-1") { this.getNodeCondition(); }
                if (tabhrefstr == "tab-2") { this.getSecurity(); }
                if (tabhrefstr == "tab-3") { this.getCertificate(); }
                if (tabhrefstr == "tab-4") { this.getLoadbalancer(); }
            },
            getDomain() {
                if (this.uuid) {
                    let urlstr = "/node_manager/" + this.uuid + "/domain";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == '""') {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              self.domainItem = {
                                  subdomain: jdata.sub_domain,
                                  gBizConnectURL: "http://" + jdata.sub_domain + ".gbcnm.hitachi-sis.co.jp:8080/setting.php",
                                  SwaggerURL: "https://" + jdata.sub_domain + ".gbcnm.hitachi-sis.co.jp/swaggerui/",
                                  SwaggerEditorURL: "https://" + jdata.sub_domain + ".gbcnm.hitachi-sis.co.jp/swaggereditor/"
                              };
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            getServerConfiguration() {
              console.log("use_advanced_config :" + this.detailItem.use_advanced_config);
              if(this.detailItem.use_advanced_config == true) {
                return "-";
              } else {
                if(this.detailItem.basic_config == "TEST") {
                  return "テスト構成";
                } else if(this.detailItem.basic_config == "SMALL") {
                  return "小規模構成";
                } else if(this.detailItem.basic_config == "MIDDLE") {
                  return "中規模構成";
                } else if(this.detailItem.basic_config == "LARGE") {
                  return "大規模構成";
                }
              }
            },
            getServerSpec() {
              if(this.detailItem.basic_config == "TEST") {
                return "0.5 vCPU  1 GB";
              } else if(this.detailItem.basic_config == "SMALL") {
                return "1 vCPU  2 GB";
              } else if(this.detailItem.basic_config == "MIDDLE") {
                return "2 vCPU  4 GB";
              } else if(this.detailItem.basic_config == "LARGE") {
                return "4 vCPU  8 GB";
              }
            },
            getNodeCondition() {
                if (this.uuid) {
                    let urlstr = "/node_manager/" + this.uuid + "/clustermonitoring";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == "{}") {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              self.nodeCondition = "実行中のタスク：" + jdata.clusters[0].runningTasksCount + "、保留中のタスク：" + jdata.clusters[0].pendingTasksCount;
                              self.statusMessage = "\"curl https://localhost/communication/status\"の結果："  + jdata.statusMessage;
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            getSecurity() {
                if (this.uuid) {
                    let urlstr = "/node_manager/" + this.uuid + "/security";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == "{}") {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              self.securityItem = {
                                  GroupName: jdata.SecurityGroups[0].GroupName,
                                  GroupId: jdata.SecurityGroups[0].GroupId,
                                  Description: jdata.SecurityGroups[0].Description,
                                  VpcId: jdata.SecurityGroups[0].VpcId
                              };
                              self.tagItem = jdata.SecurityGroups[0].Tags;
                              //�����������ンバウンドルール
                              let arry = [];
                              let ipPermissions = jdata.SecurityGroups[0].IpPermissions;
                              for (let i=0; i<ipPermissions.length; i++) {
                                let iport = "";
                                if ("FromPort" in ipPermissions[i]) {
                                  iport = ipPermissions[i].FromPort;
                                  if (ipPermissions[i].FromPort != ipPermissions[i].ToPort) {
                                    iport = iport + "-" + ipPermissions[i].ToPort;
                                  }
                                }
                                for (let j=0; j<ipPermissions[i].IpRanges.length; j++) {
                                  let idata = { protocol: ipPermissions[i].IpProtocol, port: iport, ipv4: ipPermissions[i].IpRanges[j].CidrIp };
                                  if (idata["protocol"] == "-1") { idata["protocol"] = "すべて"; }
                                  if (idata["protocol"] == "icmp") { idata["port"] = "すべて"; }
                                  arry.push(idata);
                                }
                              }
                              self.inboundItem = arry;
                              //アウトバウンドルール
                              arry = [];
                              ipPermissions = jdata.SecurityGroups[0].IpPermissionsEgress;
                              for (let i=0; i<ipPermissions.length; i++) {
                                let iport = "";
                                if ("FromPort" in ipPermissions[i]) {
                                  iport = ipPermissions[i].FromPort;
                                  if (ipPermissions[i].FromPort != ipPermissions[i].ToPort) {
                                    iport = iport + "-" + ipPermissions[i].ToPort;
                                  }
                                }
                                for (let j=0; j<ipPermissions[i].IpRanges.length; j++) {
                                  let idata = { protocol: ipPermissions[i].IpProtocol, port: iport, ipv4: ipPermissions[i].IpRanges[j].CidrIp };
                                  if (idata["protocol"] == "-1") { idata["protocol"] = "すべて"; }
                                  if (idata["protocol"] == "icmp") { idata["port"] = "すべて"; }
                                  arry.push(idata);
                                }
                              }
                              self.outboundItem = arry;
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            getCertificate() {
                if (this.uuid) {
                    let urlstr = "/node_manager/" + this.uuid + "/certificate";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == "{}") {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              //RevokedAtのキーが作られていない場合はNotAfterを設定する
                              if ("RevokedAt" in jdata.Certificate) {
                                  self.certificateItem = {
                                      RevokedAt: jdata.Certificate.RevokedAt
                                  };
                              } else {
                                  self.certificateItem = {
                                      RevokedAt: jdata.Certificate.NotAfter
                                  };
                              }
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            getLoadbalancer() {
                if (this.uuid) {
                    let urlstr = "/node_manager/" + this.uuid + "/loadbalancer";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    let bodytext = "";
                    const dec = new TextDecoder();
                    
                    window.fetch(urlstr, { method: 'GET', headers: header, mode: 'cors' })
                      .then((response) => response.body.getReader())
                      .then((reader) => {
                          function readChunk({done, value}){
                            if (done) {
                              if (bodytext == "{}") {
                                self.setMessage("E", "情報の取得に失敗しました。");
                                return;
                              }
                              let jdata = JSON.parse(bodytext);
                              if (typeof(jdata.message) != "undefined") {
                                self.setMessage("E", jdata.message);
                                return;
                              }
                              //取得した情報を設定する
                              self.loadbalancerItem = {
                                      DNSName: jdata.LoadBalancers[0].DNSName
                              };
                              return;
                            }
                            bodytext = bodytext + dec.decode(value);
                            reader.read().then(readChunk);
                          }
                          reader.read().then(readChunk);
                        })
                      .catch((error) => {
                        if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                        else { self.setMessage("E", error); } });
                }
            },
            sendCrtfile() {
                //SSl証明書をアップロードする
                this.crtError = false;
                this.crtErrorMessages = [];
                let elm = document.getElementById("crtfile");
                let file = elm.value;
                if (file == "") {
                    //ファイルが選択されていない
                    this.crtError = true;
                    this.crtErrorMessages = ["ファイルを選択して「送信」ボタンを押してください。"];
                    this.setMessage("E", "ファイルが選択されていません。エラー箇所を確認してください。");
                    return;
                }
                let filesp = file.split(".");
                if (filesp.length < 2) {
                    //拡張子が無い
                    this.crtError = true;
                    this.crtErrorMessages = ["ファイル拡張子が異なります。正しいファイルを選択してください。"];
                    this.setMessage("E", "意図しないファイルが選択されています。エラー箇所を確認してください。");
                    return;
                }
                let ext = filesp[filesp.length - 1];
                if (ext == "crt" || ext == "cer") {
                    //正常
                    const formdata = new FormData();
                    const inputFile = document.querySelector('input[id="crtfile"]');
                    formdata.append("file", inputFile.files[0]);
                    
                    let urlstr = "/node_manager/" + this.uuid + "/sslcertificate";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    //追加不要 header.append('Content-Type', 'multipart/form-data');
                    
                    let self = this;
                    
                    window.fetch(urlstr, { method: 'POST', headers: header, mode: 'cors', body: formdata })
                        .then((response) => {
                            if (response.status != 200) { self.getErrorMessage(response); self.setMessage("E", "status:" + response.status); }
                            else { self.getErrorMessage(response); self.setMessage("I", ""); } })
                        .catch((error) => {
                            if (error == "TypeError: Failed to fetch") { self.setMessage("E", "サーバへのアクセスに失敗しました。"); }
                            else { self.setMessage("E", error); } });
                } else {
                    //拡張子が異なる
                    this.crtError = true;
                    this.crtErrorMessages = ["ファイル拡張子が異なります。正しいファイルを選択してください。"];
                    this.setMessage("E", "意図しないファイルが選択されています。エラー箇所を確認してください。");
                }
            },
            sendKeyfile() {
                //SSl証明書の秘密鍵をアップロードする
                this.keyError = false;
                this.keyErrorMessages = [];
                let elm = document.getElementById("keyfile");
                let file = elm.value;
                if (file == "") {
                    //ファイルが選択されていない
                    this.keyError = true;
                    this.keyErrorMessages = ["ファイルを選択して「送信」ボタンを押してください。"];
                    this.setMessage("E", "ファイルが選択されていません。エラー箇所を確認してください。");
                    return;
                }
                let filesp = file.split(".");
                if (filesp.length < 2) {
                    //拡張子が無い
                    this.keyError = true;
                    this.keyErrorMessages = ["ファイル拡張子が異なります。正しいファイルを選択してください。"];
                    this.setMessage("E", "意図しないファイルが選択されています。エラー箇所を確認してください。");
                    return;
                }
                let ext = filesp[filesp.length - 1];
                if (ext == "key") {
                    //正常
                    const formdata = new FormData();
                    const inputFile = document.querySelector('input[id="keyfile"]');
                    formdata.append("file", inputFile.files[0]);
                    
                    let urlstr = "/node_manager/" + this.uuid + "/privatekey";
                    let header = new window.Headers();
                    header.append('Access-Control-Allow-Origin', '*');
                    
                    let self = this;
                    
                    window.fetch(urlstr, { method: 'POST', headers: header, mode: 'cors', body: formdata })
                        .then((response) => {
                            if (response.status != 200) {
                                self.getErrorMessage(response); self.setMessage("E", "status:" + response.status);
                        } else {
                            self.getErrorMessage(response); self.setMessage("I", ""); 
                        } 
                    })
                    .catch((error) => {
                        if (error == "TypeError: Failed to fetch") {
                            self.setMessage("E", "サーバへのアクセスに失敗しました。"); 
                        } else {
                            self.setMessage("E", error); 
                        } 
                    });
                } else {
                    //拡張子が異なる
                    this.keyError = true;
                    this.keyErrorMessages = ["ファイル拡張子が異なります。正しいファイルを選択してください。"];
                    this.setMessage("E", "意図しないファイルが選択されています。エラー箇所を確認してください。");
                }
            },
            inboundSetting() {
                //画面遷移
                if (this.uuid) {
                  let movestr = "/securitygroupsetting/" + this.uuid + "/in";
                  this.$router.push(movestr);
                }
            },
            outboundSetting() {
                //画面遷移
                if (this.uuid) {
                  let movestr = "/securitygroupsetting/" + this.uuid + "/out";
                  this.$router.push(movestr);
                }
            },
            certificateSetting() {
                //画面遷移
                this.setMessage("I", "現在、「証明書関連」タブの更新ボタンは動作しません。");
            },
            gogBizConnectURL() {
                window.open(this.domainItem.gBizConnectURL);
            },
            goSwaggerURL() {
                window.open(this.domainItem.SwaggerURL);
            },
            goSwaggerEditorURL() {
                window.open(this.domainItem.SwaggerEditorURL);
            },
            copygBizConnectURL() {
                window.navigator.clipboard.writeText(this.domainItem.gBizConnectURL)
                  .then(() => { this.setMessage("I", "クリップボードにコピーしました。"); })
                  .catch(e => { this.setMessage("E", "クリップボードへのコピーが失敗しました。" + e); });
            },
            copySwaggerURL() {
                window.navigator.clipboard.writeText(this.domainItem.SwaggerURL)
                  .then(() => { this.setMessage("I", "クリップボードにコピーしました。"); })
                  .catch(e => { this.setMessage("E", "クリップボードへのコピーが失敗しました。" + e); });
            },
            copySwaggerEditorURL() {
                window.navigator.clipboard.writeText(this.domainItem.SwaggerEditorURL)
                  .then(() => { this.setMessage("I", "クリップボードにコピーしました。"); })
                  .catch(e => { this.setMessage("E", "クリップボードへのコピーが失敗しました。" + e); });
            },
            copyLoadbalancerDNSName() {
                window.navigator.clipboard.writeText(this.loadbalancerItem.DNSName)
                  .then(() => { this.setMessage("I", "クリップボードにコピーしました。"); })
                  .catch(e => { this.setMessage("E", "クリップボードへのコピーが失敗しました。" + e); });
            },
            cancel() {
                //画面遷移
                this.$router.push("/");
            },
            editNode() {
                //画面遷移
                if (this.uuid) {
                    let movestr = "/updatenode/" + this.uuid + "/2";
                    this.$router.push(movestr);
                } else {
                    this.setMessage("W", "情報の取得に失敗しました。恐れ入りますが「ノード管理画面」へ戻ってください。");
                }
            }
        },
    };
</script>